import React, { useState } from 'react';

const SizeSelector = ({ sizes, defaultSize, onSizeChange }) => {
  const [selectedSize, setSelectedSize] = useState(defaultSize || sizes[0]);

  const handleSizeChange = (size) => {
    setSelectedSize(size);
    onSizeChange?.(size); // Call the callback if provided
  };

  return (
    <div className="size-selection font-helv-neue mt-4">
      <span className="text-xl tracking-wide font-semibold">Size</span>
      <div className="mt-2 size-options">
        {sizes.map((size) => (
          <button
            key={size}
            className={selectedSize === size ? 'selected' : ''}
            onClick={() => handleSizeChange(size)}
          >
            {size}
          </button>
        ))}
      </div>
    </div>
  );
};

export default SizeSelector;