// Banner.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import holomiLogo from './images/holomi-logo.png';
import { CartIcon, CartDrawer } from './Cart.js';
import ProductsDropdown from './productsDropdown.js';
import MenuDrawer  from './MenuDrawer.js';


const Banner = ({ mb = true }) => {
    const navigate = useNavigate();
    const [isCartOpen, setIsCartOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleCartDrawer = (state = !isCartOpen) => {
        setIsCartOpen(state);
    };

    const toggleMenuDrawer = (state = !isMenuOpen) => {
        setIsMenuOpen(state);
    };

    return (
        <div className={`banner flex justify-between items-center ${!mb ? "" : "mb-8"} relative p-8`}>
            <div className="left">
                <MenuDrawer/>
            </div>
            <div className="hp-logo-container absolute left-1/2 transform -translate-x-1/2">
                <img src={holomiLogo} alt="Logo" className="hp-logo" />
            </div>
            <div className="right flex space-x-8 items-center font-baloo text-xl tracking-wide">
                <button onClick={() => navigate('/')} className="nav-links">
                    Shop
                </button>
                <ProductsDropdown />
                <button onClick={() => navigate('/faq')} className="nav-links">
                    FAQ
                </button>
                <CartIcon toggleCartDrawer={toggleCartDrawer} />
            </div>
            <CartDrawer isCartOpen={isCartOpen} toggleCartDrawer={toggleCartDrawer} />
        </div>
    );
};

export default Banner;
