import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyD8nZIrEFXq9-ZS2nom8GKDbwhCVsblPV8",
  authDomain: "holomi-21cb0.firebaseapp.com",
  projectId: "holomi-21cb0",
  storageBucket: "holomi-21cb0.firebasestorage.app",
  messagingSenderId: "624901243314",
  appId: "1:624901243314:web:da69ec6c1a1b48a9e964ab",
  measurementId: "G-RNV3F92GNW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication
const auth = getAuth(app);

export { auth };