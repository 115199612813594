import React, { useState } from "react";
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';

const HoverInfoIcon = ({ text, onClick = null }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="relative flex items-center"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Tooltip */}
      {isHovered && text && (
        <div
          className="absolute -top-8 bg-gray-800 text-white text-xs px-2 py-1 rounded-lg shadow-lg whitespace-nowrap"
          style={{ zIndex: 10 }}
        >
          {text}
        </div>
      )}

      {/* Icon */}
      <QuestionMarkCircleIcon
        className="h-6 w-6 text-gray-400 ml-2 cursor-pointer translate-y-1 hover:bg-gray-100 rounded-full p-1"
        onClick={onClick}
      />
    </div>
  );
};

export default HoverInfoIcon;
