import oscarAquarelleSm from '../style_guide_pics/oscar_aquarelle_sm.jpeg';
import oscarGraffitiSm from '../style_guide_pics/oscar_graffiti_sm.jpeg';
import oscarMemphisSm from '../style_guide_pics/oscar_memphis_sm.jpeg';
import jpGirlBase from '../style_guide_pics/jgirl_base.webp';
import jpGirlTrans from '../style_guide_pics/jgirl_trans.webp';
import threeStyle from '../style_guide_pics/3style.jpeg';

export const carouselInfo = [
  {
    title: "Three style pack",
    src: threeStyle,
    alt: "Three style pack",
    description: "Choose from three different styles. Our top pick!",
    proof_label: "3 proofs x 3",
    media: [
      {
        src: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/videos%2F3style_compressed_v2.mp4?alt=media&token=68a9b8ea-be0c-4461-a4eb-d7225f240d8b",
        type: "video",
        thumbnail: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/~%2Fthumbnails%2F3style_compressed.jpeg?alt=media&token=ebe67a20-b176-421a-9a39-5b1dc4b3f577"
      }
    ],
    tags: ["Variety Pack", "Recommended"]
  },
  {
    title: "Webtoon",
    src: jpGirlTrans,
    alt: "Webtoon",
    description: "A style inspired by the webtoon genre, with a focus on vibrant colors and a dreamy aesthetic.",
    media: [
      {
        src: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/videos%2Fjgirl_enchnated_compressed.mp4?alt=media&token=27ffe684-ad16-48d4-a626-c71a1a23d905",
        type: "video",
        thumbnail: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/~%2Fthumbnails%2Fjgirl_enchnated_compressed.jpeg?alt=media&token=a286a240-cdc8-4819-8fd4-297f678f0d70"
      },
      {
        src: jpGirlBase,
        trans: jpGirlTrans,
        type: "slider"
      }
    ],
    tags: ["Anime", "People", "Portrait"]
  },
  {
    title: "Pixar Style",
    src: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Fred_girl_trans_compressed.jpeg?alt=media&token=f5f3203e-c4b7-4279-8667-d504a3d25726",
    thumbnail: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Fred_girl_trans_thumb.jpeg?alt=media&token=c623f90e-663b-4877-9813-794b9ff8d7e6",
    alt: "Pixar Style",
    description: "A style inspired by the animations of Pixar and Dreamworks.",
    media: [
      {
        // TBL: Change this to higher quality video
        src: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/videos%2Fwgirl_pixar_compressed.mp4?alt=media&token=dae20142-cc4a-4c15-9054-de26f2ec4927",
        type: "video",
        thumbnail: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/~%2Fthumbnails%2Fwgirl_pixar_compressed.jpeg?alt=media&token=2efac5d7-4abe-4f5c-997b-33d4b2677a03"
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Fred_girl_bg_modified_compressed.jpeg?alt=media&token=374e3adf-aa19-4447-bcb4-376710b536fa",
        trans: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Fred_girl_trans_compressed.jpeg?alt=media&token=f5f3203e-c4b7-4279-8667-d504a3d25726",
        type: "slider"
      }
    ],
    tags: ["3D", "People", "Portrait"]
  },
  {
    title: "Harajuku",
    src: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Fsgg_neon_thumb.jpeg?alt=media&token=897ea201-3a47-44cd-9265-08a1565a272b",
    alt: "Harajuku",
    description: "A style inspired by the Harajuku fashion scene, with a focus on neon colors and bold patterns.",
    media: [
      {
        src: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/videos%2Fharajuku_girl_compressed_v2.mp4?alt=media&token=7266f9a9-8dc8-40d9-9069-f1b7af6dbf4a",
        type: "video",
        thumbnail: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/~%2Fthumbnails%2Fharajuku_girl_compressed_v2.jpeg?alt=media&token=c5a1e1c7-1d65-487d-813a-3ddf094f09ee"
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Fsgg_base_compressed.jpeg?alt=media&token=2985af53-f71b-45fa-83cb-5c343313653d",
        trans: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Fsgg_neon_compressed.jpeg?alt=media&token=bcce9477-03ce-4eeb-b736-075377e69a65",
        type: "slider"
      },
    ],
    tags: ["Anime", "People", "Portrait"]
  },
  {
    title: "Warm Hearth",
    src: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Fcouple_base%20Large_compressed.jpeg?alt=media&token=9d7457d6-cfc8-4b71-a337-784c4b454adc",
    thumbnail: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Fcouple_trans%20Large_compressed.jpeg?alt=media&token=db27b382-51dc-4eac-a4aa-37174d550d6e",
    alt: "Warm Hearth",
    description: "A style inspired by cozy home decor, with a focus on warm colors and rustic textures.",
    media: [
      {
        src: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/videos%2Fcouple_warm_compressed.mp4?alt=media&token=cc81ecd5-9aa3-487d-9677-f1087f1b987b",
        type: "video",
        thumbnail: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/~%2Fthumbnails%2Fcouple_warm_compressed.jpeg?alt=media&token=fc126459-7b40-4160-b367-4cf8dd65dad5"
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Fcouple_base%20Large_compressed.jpeg?alt=media&token=9d7457d6-cfc8-4b71-a337-784c4b454adc",
        trans: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Fcouple_trans%20Large_compressed.jpeg?alt=media&token=db27b382-51dc-4eac-a4aa-37174d550d6e",
        type: "slider"
      }
    ],
    tags: ["Abstract", "People", "Poses"]
  },
  // {
  //   title: "Harmony",
  //   src: sggMulan,
  //   alt: "Harmony",
  //   description: "A style inspired by the movie Mulan, with a focus on traditional Chinese art and culture.",
  //   media : [
  //     {
  //       src: productDemo,
  //       type: "video"
  //     },
  //   ]
  // },
  {
    title: "Dreamy Paint",
    src: oscarAquarelleSm,
    alt: "Dreamy Paint",
    description: "A style inspired by watercolor paintings, with a focus on soft colors and dreamy textures.",
    media: [
      {
        src: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Foscar_base_compressed.jpeg?alt=media&token=6020f9f4-47d5-4674-94d4-1a9410084ec0",
        trans: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Foscar_aquarelle_compressed.jpeg?alt=media&token=20436ff5-f998-41dd-a0c5-17f9c966f66b",
        type: "slider"
      }
    ],
    tags: ["People", "Pets"]
  },
  {
    title: "Nightcall",
    src: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Faa_base%20Large_compressed.jpeg?alt=media&token=65357523-01b4-47e3-bca9-1de079371fd7",
    thumbnail: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Faa_trans%20Large_thumb.jpeg?alt=media&token=e01dc600-dd87-4e9b-bbcd-b66761fc4c81",
    alt: "Nightcall",
    description: "A style inspired by the night sky, with a focus on dark colors and glowing accents.",
    media: [
      {
        src: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Faa_base%20Large_compressed.jpeg?alt=media&token=65357523-01b4-47e3-bca9-1de079371fd7",
        trans: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Faa_trans%20Large_compressed.jpeg?alt=media&token=1546a9e5-dd90-47e4-8b1b-74edcf929e24",
        type: "slider"
      }
    ],
    tags: ["People", "Portrait"]
  },
  // {
  //   title: "Pixel Art",
  //   src: oscarPixel,
  //   alt: "Pixel Art",
  //   description: "A style inspired by retro video games, with a focus on pixelated graphics and bright colors.",
  //   media : [
  //     {
  //       src: productDemo,
  //       type: "video"
  //     },
  //   ]
  // },
  {
    title: "Graffiti",
    src: oscarGraffitiSm,
    alt: "Graffiti",
    description: "A style inspired by street art, with a focus on bold colors and expressive designs.",
    media: [
      {
        src: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Foscar_base_compressed.jpeg?alt=media&token=6020f9f4-47d5-4674-94d4-1a9410084ec0",
        trans: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Foscar_graffiti_compressed.jpeg?alt=media&token=5d8b6160-0fc4-4ca9-b11c-07be0a1a25a8",
        type: "slider"
      }
    ],
    tags: ["People", "Portrait", "Poses"]
  },
  {
    title: "Posh sketch",
    src: oscarMemphisSm,
    alt: "Posh sketch",
    description: "A style inspired by modern design, with a focus on geometric shapes and bold patterns.",
    media: [
      {
        src: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Foscar_base_compressed.jpeg?alt=media&token=6020f9f4-47d5-4674-94d4-1a9410084ec0",
        trans: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Foscar_memphis_compressed.jpeg?alt=media&token=c40feb38-047e-469b-88e8-2873ebd1518d",
        type: "slider"
      },
    ],
    tags: ["Abstract", "People", "Pets", "Poses"]
  }
]
