import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import './altHomePage.css'; // Assume custom CSS for styling

import { isMobile } from 'react-device-detect';
import { carouselInfo } from './imageSelectionConstants.js';
import { FaCreditCard, FaShoppingCart, FaPhotoVideo } from 'react-icons/fa'; // Import shopping cart icon
import { v4 as uuidv4 } from 'uuid';
// Our own defined components.
import Banner from './Banner.js';
import MediaGallery from './MediaGallery.js';
import SizeSelector from './SizeSelector.js';
import CopiesSelector from './NumCopiesSelector.js';
import HoverInfoIcon from './InfoHoverWidget.js'
import { OrderContext } from './OrderContext';
import Tag from './Tag.js';
import ApplePayButton from './ApplePayButton.js'

import { useNavigate } from 'react-router-dom';

// Defined constants and helper functions
import * as Constants from './Constants.js';
import * as Utils from './utils.js';

import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';

const AltHomePage = () => {
  const navigate = useNavigate();


  const [selectedSize, setSelectedSize] = useState('5x5');
  const [selectedStyleIndex, setSelectedStyleIndex] = useState(0);
  const [selectedCopies, setSelectedCopies] = useState(1);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [uploadedImage, setUploadedImage] = useState([]);
  const [specialRequests, setSpecialRequests] = useState('');
  const [selectedPackStyles, setSelectedPackStyles] = useState(['', '', '']); // Track selected styles for 3 Style Pack


  const [showCheckout, setShowCheckout] = useState(false);
  const modalRef = useRef(null);
  const howItWorksModalRef = useRef(null);

  const { orders, addOrder } = useContext(OrderContext);

  const fetchClientSecret = () => {
    var orders_to_send = orders;
    // Current order already validated, ok to push.
    if (uploadedImage.length !== 0) {
      orders_to_send = orders_to_send.concat(makeOrder());
    }
    console.log("Orders being sent:", orders_to_send);
    return fetch(Constants.SERVER_BASE_URL + "/create-checkout-session", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json', // Add this header
      },
      body: JSON.stringify({ orders: orders_to_send }),
    })
    .then((res) => {
      if (!res.ok) {
        return res.json().then((data) => {
          // If the response contains an error message, include it in the error
          const errorMessage = data.error || `Request failed with status ${res.status}`;
          throw new Error(errorMessage);
        });
      }
      return res.json();
    })
    .then((data) => data.clientSecret)
    .catch((error) => {
      // Properly log the error message and full error object
      console.error('Error creating checkout session:', error.message || error);
      alert(error.message || 'An unexpected error occurred.'); // Show a meaningful error to the user
      throw error; // Re-throw the error for further handling if needed
    });

  };
  const options = { fetchClientSecret };

  const handleCheckoutClick = () => {
    if (orders.length === 0 && uploadedImage.length === 0) {
      alert("Please add an item to the cart before checking out.");
      return;
    }
    if (uploadedImage.length > 0 && !isValidOrder()) {
      return;
    }
    setShowCheckout(true);
    modalRef.current?.showModal();
  };

  const handleCloseModal = () => {
    setShowCheckout(false);
    modalRef.current?.close();
  };

  const handleOpenProofModal = () => {
    howItWorksModalRef.current?.showModal();
  };
  const handleCloseProofModal = () => {
    howItWorksModalRef.current?.close();
  };

  const handleOutsideClick = useCallback((event) => {
    if (!event.target.closest('.dropdown')) {
      setIsDropdownOpen(false);
    }
  }, []);

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener('click', handleOutsideClick);
    } else {
      document.removeEventListener('click', handleOutsideClick);
    }
    return () => document.removeEventListener('click', handleOutsideClick);
  }, [isDropdownOpen]);

  const handleCopiesChange = (copies) => {
    setSelectedCopies(copies);
  };

  const handleSelectedStyle = (index) => {
    setSelectedStyleIndex(index);
  }

  const handlePackStyleChange = (index, value) => {
    const updatedStyles = [...selectedPackStyles];
    updatedStyles[index] = value;
    setSelectedPackStyles(updatedStyles);
  };


  const handleImageChange = (event, is_before = false) => {
    const files = Array.from(event.target.files);
    const previews = files.map((file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve({ src: reader.result, name: file.name });
        reader.readAsDataURL(file);
      });
    });

    // Wait for all previews to be generated
    Promise.all(previews).then((images) => {
      setUploadedImage([images[0].src]);
    });
  };

  const makeOrder = () => {
    const style = selectedStyleIndex === 0 ? selectedPackStyles.join('//') : carouselInfo[selectedStyleIndex].alt;
    return {
      id: uuidv4(),
      imagePairs: [uploadedImage[0], null],
      mode: Constants.LITU,
      size: selectedSize,
      copies: selectedCopies,
      specialRequest: specialRequests,
      style: style,
    }
  };

  const isValidOrder = () => {
    if (uploadedImage.length === 0) {
      alert('Please upload an image before adding to cart.');
      return false;
    }

    console.log("Selected Pack Styles:", selectedPackStyles);
    if (selectedStyleIndex === 0 &&
      selectedPackStyles.every((style) => style === "")) {
      alert('Please select at least one unique style before adding to cart.');
      return false;
    }
    return true;
  }
  const handleAddToCart = async () => {
    // The user 
    if (!isValidOrder()) {
      return;
    }

    console.log("Selected Pack Styles:", selectedPackStyles);
    if (selectedStyleIndex === 0 &&
      selectedPackStyles.every((style) => style === "")) {
      alert('Please select at least one unique style before adding to cart.');
      return;
    }

    try {
      // Add the order and await its completion
      await addOrder(makeOrder());
      window.location.reload();
    } catch (error) {
      console.error('Failed to add item to cart:', error);
      alert('There was an error adding the item to your cart. Please try again.');
    }
  };


  const range = (start, end) => Array.from({ length: end - start + 1 }, (_, i) => start + i);

  return (
    <div className="product-page bg-container min-h-screen min-w-screen">
      <Banner />
      <MediaGallery key={selectedStyleIndex} activeMediaIndex={selectedStyleIndex} />
      <div className="product-info font-baloo bg-white text-off-black border border-gray-300 shadow-lg rounded-lg p-6">
        <div className="flex flex-col">
          <text className="font-helv-neue font-bold tracking-wider text-3xl">{carouselInfo[selectedStyleIndex].title.toUpperCase()}</text>
          <span className="font-helv-neue mt-2">{carouselInfo[selectedStyleIndex].description}</span>
          {/* Tag Section */}
          <div className="flex flex-wrap mt-2">
            {carouselInfo[selectedStyleIndex].tags?.map((tag, index) => (
              <Tag key={index} text={tag} />
            ))}
          </div>
          <div className="flex items-center">
            <text className="font-helv-neue mt-4">{carouselInfo[selectedStyleIndex].proof_label || `${Constants.NUM_PROOFS} proofs`}</text>
          </div>
        </div>
        <p className="price flex flex-row items-baseline">
          <span className="current-price text-2xl">$5</span><span className="font-helv-neue text-xs ml-2">You pay today</span>
          <HoverInfoIcon onClick={handleOpenProofModal} />
        </p>
        <p className="flex flex-row items-baseline">
          <span className="text-xl align-bottom">${Constants.getPrice(selectedSize) - 5}</span>
          <span className="font-helv-neue text-xs ml-2 align-bottom">Price after proof confirmation</span>
        </p>

        {/* Styles Section */}
        <div className="styles-section">
          <div className="text-xl tracking-wide font-semibold font-helv-neue mt-8">Styles</div>
          <div className="style-options flex flex-wrap rounded mt-2">
            {carouselInfo.map((item, index) => (
              <div key={index} className="style-option relative group">
                <img
                  src={item.thumbnail ? item.thumbnail : item.src}
                  alt={item.alt}
                  className={`style-image border-2 p-2 w-[80px] h-[80px]  ${selectedStyleIndex === index ? "border-blue-300 border-2" : ""}  
                  hover:border-blue-300 hover:border-2`}
                  onClick={() => handleSelectedStyle(index)}
                />
                <div className="absolute bg-less-off-black text-white text-xs p-1 rounded shadow-md hidden group-hover:block z-10 whitespace-nowrap" style={{ left: '50%', transform: 'translateX(20%), translateY(40%)' }}>
                  {item.alt}
                </div>
              </div>
            ))}
          </div>
        </div>

        {selectedStyleIndex === 0 && (
          <div className="style-pack-section mt-4 font-helv-neue flex flex-col">
            <h3 className="text-lg font-semibold">Select up to three styles:</h3>
            {[0, 1, 2].map((index) => (
              <select
                key={index}
                value={selectedPackStyles[index] || ""} // Set default if no value is selected
                onChange={(e) => handlePackStyleChange(index, e.target.value)}
                className="font-helv-neue mt-2 p-2 border border-gray-300 rounded w-[200px] bg-white"
              >
                {/* Default placeholder option */}
                <option value="">
                  No style selected
                </option>

                {carouselInfo.slice(1).map((item, styleIndex) => (
                  <option
                    key={styleIndex}
                    value={item.alt}
                    disabled={selectedPackStyles.includes(item.alt) && selectedPackStyles[index] !== item.alt}
                  >
                    {item.alt}
                  </option>
                ))}
              </select>
            ))}
          </div>
        )}

        <SizeSelector
          sizes={['5x5', '8x10']}
          onSizeChange={setSelectedSize}
        />
        <CopiesSelector maxCopies={10} isMobile={isMobile} onCopiesChange={setSelectedCopies} />
        <div className="upload-section mb-6">
          <p className="font-helv-neue">Upload your picture before adding to cart.</p>
          <input
            id="file-upload"
            type="file"
            accept="image/*"
            onChange={(event) => handleImageChange(event)}
            style={{ display: 'none' }}
          />
          <label
            htmlFor="file-upload"
            className="cursor-pointer add-to-cart btn btn-success text-[16px] text-off-black w-[180px] mt-2 font-helv-neue"
          >
            <FaPhotoVideo className="mr-2" />Choose Image
          </label>
          {uploadedImage.length > 0 && (
            <div className="my-4">
              {
                uploadedImage.map((image_str, index) => (
                  image_str !== "" ?
                    (
                      <img
                        src={image_str}
                        alt="generated image"
                        className="h-32 object-contain shadow-lg border border-white border-8"
                      />) : null
                ))}
            </div>)}
        </div>

        <div className="textbox-container font-helv-neue">
          <label className="textbox-title" htmlFor="specialRequests">
            Special requests
          </label>
          <textarea
            id="specialRequests"
            className="textbox-input"
            rows="4"
            placeholder="Let us know any special requests you have for your image!"
            onChange={(event) => setSpecialRequests(event.target.value)}
          ></textarea>
        </div>

        <button className={`add-to-cart text-[16px] btn btn-md btn-success text-off-black py-2 px-0 w-[180px] flex flex-row font-helv-neue`}
          onClick={handleAddToCart}>
          <FaShoppingCart className="mr-2" />
          Add to cart
        </button>

        {/* Button that opens the modal */}
        <button onClick={handleCheckoutClick} className="btn btn-success btn-lg w-full custom-bg my-4 font-helv-neue">
          <FaCreditCard className="mr-2" />
          Checkout
        </button>

        <ApplePayButton />

        {/* Modal content */}
        <dialog ref={modalRef} className="modal">
          <div className="modal-box w-100 max-w-screen-2xl">
            <div className="py-4">
              {showCheckout && (
                <EmbeddedCheckoutProvider stripe={Constants.stripePromise} options={options}>
                  <EmbeddedCheckout />
                </EmbeddedCheckoutProvider>
              )}
            </div>
            <div className="modal-action">
              <form method="dialog">
                <button className="btn" onClick={handleCloseModal}>
                  Close
                </button>
              </form>
            </div>
          </div>
        </dialog>

        {/* Proof Modal */}
        <dialog ref={howItWorksModalRef} className="modal">
          <div className="modal-box w-11/12 max-w-5xl font-helv-neue">
            <h3 className="text-2xl tracking-wide border-b">How it works</h3>
            <p className="py-4 text-md">
              Our expert team will craft custom photos just for you. We’ll email you a selection of proofs, and you pick the one(s) that resonates most. After you make your choice, we’ll move forward with printing.
              <br />
              <br />
              You’ll only pay the full price once you’re happy with your selected proof.
              <br />
              <br />
              Please allow up to 24 hours for us to create and send your proofs.
            </p>
            <div className="modal-action">
              <form method="dialog">
                {/* if there is a button, it will close the modal */}
                <button className="btn add-to-cart">Close</button>
              </form>
            </div>
          </div>
        </dialog>
      </div>
    </div >
  );
};

export default AltHomePage;