import { loadStripe } from '@stripe/stripe-js';

const LITU = 'leaveittous'
const GYO = 'generateyourown'
const UYO = 'uploadafterimage'

function getPrice(size) {
  if (size === '5x5') {
    return "29.99"
  }
  if (size === '8x10') {
    return "44.99"
  }
  return "Unknown";
}

const PROOF_FEE = 5.00;

const stripePromise = loadStripe('pk_test_51QGMpVGGk3dbgPJxp9N3WBsGBVQg0Zl1AYTjUFb4JOEb68RgHQEb6F4Klc0MtgCrOWaAGyxbhtLA3IcqUQLdAlzb00agA8wUBm'); // Replace with actual Stripe public key


const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL || "";

const NUM_PROOFS = 9;

export { LITU, GYO, UYO, getPrice,PROOF_FEE, SERVER_BASE_URL, stripePromise, NUM_PROOFS };