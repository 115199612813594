import React, { useState } from "react";

const tagColors = {
  Anime: "#FAD5E5",      // Soft pink
  People: "#A3D8F4",     // Light blue
  Pets: "#D7F9C4",       // Pale green
  Poses: "#FFE8A3",      // Soft yellow
  Abstract: "#E1D4F9",   // Lavender
  "3D": "#FFC7CE",       // Light coral
  Portrait: "#FBD1BD",   // Light rose
  "Variety Pack": "#F8D7E3",    // Pastel pink
  Recommended: "#F6E3B4",      // Light gold
  Default: "#E2E8F0",    // Light grey
};

const tagDescriptions = {
  Anime: "Based on anime style",
  People: "Suited for crafting visuals of people",
  Pets: "Suited for crafting visuals of pets",
  Poses: "Recommended for representing photographs not necessarily taken from the front",
  Abstract: "A style that removes a lot of detail",
  "3D": "A 3D-based style",
  Portrait: "Recommended for head-on photographs",
  "Variety Pack": "You select different styles!",
}

const Tag = ({ text }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="relative flex flex-col items-center"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Tooltip for Description */}
      {isHovered && tagDescriptions[text] && (
        <div
          className="absolute -top-8 bg-gray-800 text-white text-xs px-2 py-1 rounded-lg shadow-md whitespace-nowrap font-helv-neue"
          style={{ zIndex: 10 }}
        >
          {tagDescriptions[text]}
        </div>
      )}
      {/* Tag */}
      <div
        className="rounded-lg px-3 py-1 text-sm font-helv-neue mr-2 mb-2"
        style={{
          backgroundColor: tagColors[text] || tagColors.Default,
          color: "#333333", // Adjust text color for readability
        }}
      >
        {text}
      </div>
    </div>
  );
};

export default Tag;
