import React, { useState, useEffect, useRef } from 'react';
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';
import { carouselInfo } from './imageSelectionConstants.js';
import './MediaGallery.css';

const MediaGallery = ({ activeMediaIndex }) => {
  const [activeThumbnailIndex, setActiveThumbnailIndex] = useState(0);
  const videoRefs = useRef([]);
  const [videoIsLoaded, setVideoIsLoaded] = useState(false);
  const activeMediaRef = useRef(null);
  const mediaContainerRef = useRef(null);

  const adjustContainerHeight = () => {
    if (activeMediaRef.current && mediaContainerRef.current) {
      const isMobile = window.innerWidth <= 768; // Mobile screen size threshold
      const maxHeight = isMobile ? 300 : 600; // 300px for mobile, 600px for desktop

      let height;

      if (
        carouselInfo[activeMediaIndex]?.media[activeThumbnailIndex]?.type ===
        'video'
      ) {
        const thumbnailImage = mediaContainerRef.current.querySelector(
          `.media-item:nth-child(${activeMediaIndex + 1}) .video-thumbnail`
        );
        height = thumbnailImage.offsetHeight > 0 ? thumbnailImage.offsetHeight : maxHeight;
      } else {
        height = activeMediaRef.current.offsetHeight > 0 ? activeMediaRef.current.offsetHeight : maxHeight;
      }

      height = height > maxHeight ? maxHeight : height;
      mediaContainerRef.current.style.height = `${height}px`;

      if (
        carouselInfo[activeMediaIndex]?.media[activeThumbnailIndex]?.type ===
        'video'
      ) {
        activeMediaRef.current.style.transform = isMobile
          ? 'translateY(-20px)'
          : 'translateY(0)';
      }
    }
  };

  useEffect(() => {
    setActiveThumbnailIndex(0); // Reset thumbnails when activeMediaIndex changes
    setVideoIsLoaded(false);
  }, [activeMediaIndex]);

  useEffect(() => {
    adjustContainerHeight();
    const timer = setTimeout(() => {
      adjustContainerHeight();
    }, 100); // Delay to ensure DOM updates

    return () => clearTimeout(timer);
  }, [activeMediaIndex, activeThumbnailIndex]);

  useEffect(() => {
    window.addEventListener('resize', adjustContainerHeight);
    return () => {
      window.removeEventListener('resize', adjustContainerHeight);
    };
  }, []);


  const setVideoAttributes = (video) => {
    if (video) {
      video.defaultMuted = true;
      video.muted = true;
      video.playsInline = true;
    }
  };

  const handleThumbnailClick = (index) => {
    setActiveThumbnailIndex(index);
  };

  return (
    <div className="media-gallery px-2 mb-2 relative">
      <div
        ref={mediaContainerRef}
        className="media-container relative"
        style={{
          width: '100%',
          overflow: 'hidden',
        }}
      >
        {carouselInfo.map((mediaCollection, mediaIndex) => (
          <div
            key={mediaIndex}
            className="media-item"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              visibility: activeMediaIndex === mediaIndex ? 'visible' : 'hidden',
              zIndex: activeMediaIndex === mediaIndex ? 2 : 1,
              pointerEvents: activeMediaIndex === mediaIndex ? 'auto' : 'none',
            }}
          >
            {mediaCollection.media.map((media, thumbnailIndex) => (
              <div
                key={thumbnailIndex}
                ref={
                  activeThumbnailIndex === thumbnailIndex &&
                    activeMediaIndex === mediaIndex
                    ? activeMediaRef
                    : null
                }
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  visibility:
                    activeThumbnailIndex === thumbnailIndex &&
                      activeMediaIndex === mediaIndex
                      ? 'visible'
                      : 'hidden',
                  zIndex:
                    activeThumbnailIndex === thumbnailIndex &&
                      activeMediaIndex === mediaIndex
                      ? 2
                      : 1,
                  pointerEvents:
                    activeThumbnailIndex === thumbnailIndex &&
                      activeMediaIndex === mediaIndex
                      ? 'auto'
                      : 'none',
                }}
              >
                {media.type === 'video' ? (
                  <div>
                    {mediaIndex === activeMediaIndex && (
                      <video
                        ref={(el) => {
                          videoRefs.current[mediaIndex] = el;
                          setVideoAttributes(el); // Set attributes using ref
                        }}
                        loop
                        autoPlay
                        className="rounded w-full h-full object-cover absolute"
                        onCanPlay={() => setVideoIsLoaded(true)}
                      >
                        <source src={media.src} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    )}
                    <img
                      src={media.thumbnail}
                      alt="Media"
                      className={`video-thumbnail rounded w-full h-full object-cover ${videoIsLoaded ? 'opacity-0' : ''
                        } blur`}
                      style={{
                        position: 'relative',
                        width: '100%',
                        transition: 'opacity 0.3s',
                      }}
                    />
                  </div>
                ) : media.type === 'image' ? (
                  <img
                    src={media.src}
                    alt="Media"
                    className="main-image rounded w-full object-cover"
                  />
                ) : (media.type === 'slider' && mediaIndex === activeMediaIndex) ? (
                  <div className="w-full h-full object-cover">
                    <ReactBeforeSliderComponent
                      firstImage={{
                        imageUrl: media.src,
                      }}
                      secondImage={{
                        imageUrl: media.trans,
                      }}
                    />
                  </div>
                ) : (
                  <div>No media available</div>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>

      <div className="thumbnail-row flex flex-row overflow-x-auto mt-2">
        {carouselInfo[activeMediaIndex].media.map((item, thumbnailIndex) => (
          <div
            key={thumbnailIndex}
            className={`thumbnail ${activeThumbnailIndex === thumbnailIndex ? 'active' : ''
              } gallery-thumbnail ${thumbnailIndex !== 0 ? 'ml-2' : ''
              } rounded cursor-pointer transform transition duration-200 hover:opacity-80 active:scale-95`}
            onClick={() => handleThumbnailClick(thumbnailIndex)}
          >
            <img
              src={item.thumbnail || item.src}
              alt="Thumbnail"
              className="thumbnail-image w-full h-full object-cover rounded-md"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MediaGallery;
