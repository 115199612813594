import React, { useState } from "react";
import {useNavigate} from 'react-router-dom';

const MenuDrawer = () => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const closeDrawer = () => {
    setIsOpen(false);
  };

  return (
    <div className="block menu-drawer">
      {/* Menu Icon */}
      <button
        onClick={toggleDrawer}
        className="p-2 text-white bg-black fixed top-4 left-4 z-50"
      >
        ☰
      </button>

      {/* Drawer */}
      <div
        className={`fixed top-0 left-0 h-full bg-gray-800 text-white w-64 transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 shadow-lg z-40`}
      >
        <button
          onClick={closeDrawer}
          className="absolute top-4 right-4 text-xl font-bold"
        >
          ✕
        </button>
        <ul className="mt-20 space-y-1 pl-4 text-lg font-medium">
          <li onClick={()=>{navigate("/")}} className="hover:text-gray-300 cursor-pointer">Shop</li>
          <li onClick={()=>{navigate("/faq")}} className="hover:text-gray-300 cursor-pointer">FAQ</li>
        </ul>
      </div>

      {/* Backdrop */}
      {isOpen && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-30"
          onClick={closeDrawer}
        />
      )}
    </div>
  );
};

export default MenuDrawer;
