import React from 'react';
import Banner from './Banner.js'
import { CartDrawer } from './Cart.js';

const FAQPage = () => {
  const faqData = [
    {
      question: "How does this all work?",
      answer: `<b>1)</b> Choose your preferred styles and checkout.<br/><br/>
               <b>2)</b> Our team will craft high-quality custom photos for you.<br/><br/>
               <b>3)</b> We’ll email you a set of samples<br/><br/>
               <b>4)</b> Choose your favorite ones, pay the full price, and we will proceed with shipping.`
    },
    {
      question: "Do you have any tips for selecting a style?",
      answer: `<b>1)</b> Different styles look better with different types of photos. Please check out the tags under each style to see if your picture is a good fit.<br/><br/>
               <b>2)</b> Try to pick images with clear subjects to guarantee the best result. You don't have to, but it helps!`
    },
    {
      question: "I don't like my proofs!",
      answer: `We apologize in advance if this happens to you. We try to generate quality proofs, but sometimes they may not match your expectations. We are unable to provide refunds on the proof fee. <br/><br/> <b>However,\
      on the occasion that we are unable to generate a proof on our end, we will refund the proof fee.</b>`
    },
    {
      question: "Why is there a proof fee?",
      answer: "Surprisingly, it takes some effort to generate a picture of good quality. We have to charge a small cost for that labor."
    },
    {
      question: "How long does proof generation take?",
      answer: "Please allow up to 24 hours for us to create and send your proofs. We’ll notify you as soon as they’re ready for review."
    },
    {
      question: "How long before I get my prints?",
      answer: "After proof confirmation, please allow 5-7 days to receive your print. We do not currently have priority shipping available."
    },
    {
      question: "What if I have questions about the styles?",
      answer: "Our team is here to help! Feel free to contact us with any questions about styles or customization, and we’ll guide you through the options."
    },
    {
      question: "How can I get in touch?",
      answer: "Please contact us at support@holomi.us"
    }
  ];

  return (
    <div className="bg-container bg-white">
      <Banner />
      <div className="max-w-4xl mx-auto my-12 p-6 font-baloo">
        <div className="rounded">
          <h1 className="text-5xl font-bold mb-10 text-center text-gray-800 text-soft-pink">FAQ</h1>
          <div className="space-y-6">
            {faqData.map((faq, index) => (
              <div key={index} className="faq-item bg-white border border-gray-200 rounded-lg shadow-md p-4">
                <h3 className="font-semibold text-xl text-gray-800 mb-2">
                  {faq.question}
                </h3>
                <p
                  className="text-gray-600 text-lg"
                  dangerouslySetInnerHTML={{ __html: faq.answer }}
                ></p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQPage;
