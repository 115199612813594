import React, { useState, useEffect, useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import ConfirmationWidget from './ConfirmationWidget.js';
import holomiLogo from './images/holomi-logo.png';
import { OrderContext } from './OrderContext.js';
import * as Constants from './Constants.js';
import './ConfirmationPage.css';

const ConfirmationPage = () => {
  const { state } = useLocation();
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');
  const [orderNumber, setOrderNumber] = useState('');

  const { clearOrders } = useContext(OrderContext);

  // Fetch session status when the component mounts
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');
    console.log("Fetching session status");

    fetch(`${Constants.SERVER_BASE_URL}/session-status?session_id=${sessionId}`)
      .then((res) => res.json())
      .then((data) => {
        setStatus(data.status);
        setCustomerEmail(data.customer_email);
        setOrderNumber(data.orderNumber);
      })
      .catch((err) => {
        console.error("Error fetching session status:", err);
        setStatus('error');
      });
  }, []);

  // Clear orders only when status becomes 'complete'
  useEffect(() => {
    if (status === 'complete') {
      console.log("Running handleClearOrders");
      handleClearOrders();
    }
  }, [status]); // Only run when `status` changes to 'complete'

  const handleClearOrders = async () => {
    try {
      await clearOrders();
    } catch (error) {
      console.error('Error clearing orders:', error);
    }
  };

  // Handle different statuses
  if (status === 'open') {
    alert("Something went wrong with your order. Please try again.");
    return <Navigate to="/" />;
  }

  if (status === 'complete') {
    return (
      <ConfirmationWidget
        holomiLogo={holomiLogo}
        customerEmail={customerEmail}
        orderNumber={orderNumber}
      />
    );
  }

  return <div>Loading...</div>;
};

export default ConfirmationPage;
