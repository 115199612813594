import React, { useState, useEffect, useContext } from 'react';
import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import { OrderContext } from './OrderContext';

const calcPrice = (orders) => {
  let total = 0;
  orders.forEach(() => {
    total += 500; // Example price per order
  });
  return total;
};

const calcLabel = (orders) => {
  let label = "";
  orders.forEach((order) => {
    label += `${order.size} ${order.style} /// `;
  });
  return label.trim();
};

const ApplePayButton = () => {
  const { orders } = useContext(OrderContext);
  const navigate = useNavigate();
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [canShowButton, setCanShowButton] = useState(false);

  const updatePaymentRequestInstance = (event) => {
    paymentRequest.update({
      total: {
        label: calcLabel(orders),
        amount: calcPrice(orders),
      }
    });
    if (orders.length === 0) {
      alert("Please add items to your cart before checking out.");
      event.preventDefault();
      return false;
    }
  }

  useEffect(() => {
    if (stripe) {
      console.log('Stripe loaded');
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: calcLabel(orders),
          amount: calcPrice(orders),
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check for available payment methods
      pr.canMakePayment().then((result) => {
        console.log('canMakePayment result:', result);
        if (result) {
          setPaymentRequest(pr);
          setCanShowButton(true);
        } else {
          setCanShowButton(false);
        }
      });

      // Payment method event handler
      const handlePaymentMethod = async (e) => {
        try {
          const email = e.paymentMethod.billing_details.email;
          const name = e.paymentMethod.billing_details.name;

          // Fetch Payment Intent client secret
          const response = await fetch('/create-checkout-session-alt', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ orders, email }),
          });

          if (!response.ok) {
            throw new Error(await response.json().error);
          }

          const { clientSecret } = await response.json();

          // Confirm payment intent
          const confirmResponse = await fetch('/confirm-payment-intent', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              paymentIntentClientSecret: clientSecret,
              paymentMethodId: e.paymentMethod.id,
              email,
              name,
            }),
          });

          if (!confirmResponse.ok) {
            e.complete('fail');
            throw new Error('Failed to confirm payment intent');
          }

          const { success, orderNumber, customerEmail } = await confirmResponse.json();
          if (success) {
            e.complete('success');
            console.log('Payment successful!');
            navigate(`/confirmation-apple-pay?orderNumber=${orderNumber}&customerEmail=${encodeURIComponent(customerEmail)}`);
          } else {
            e.complete('fail');
          }
        } catch (error) {
          console.error('Payment failed:', error);
          e.complete('fail');
        }
      };

      pr.on('paymentmethod', handlePaymentMethod);

      // Cleanup function to remove the event listener
      return () => {
        pr.off('paymentmethod', handlePaymentMethod);
      };
    }
  }, [stripe, orders]);

  if (canShowButton && paymentRequest) {
    return (
      <div>
        <PaymentRequestButtonElement
          options={{
            paymentRequest,
          }}
          onClick={updatePaymentRequestInstance}
        />
      </div>
    );
  }
};

export default ApplePayButton;
