import React, { useState, useEffect, useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import ConfirmationWidget from './ConfirmationWidget.js';
import holomiLogo from './images/holomi-logo.png';
import { OrderContext } from './OrderContext.js';
import './ConfirmationPage.css';

const ConfirmationPageApplePay = () => {
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const orderNumber = urlParams.get('orderNumber');
  const customerEmail = urlParams.get('customerEmail');

  const { clearOrders } = useContext(OrderContext);

  clearOrders();

  return (
    <ConfirmationWidget
      holomiLogo={holomiLogo}
      customerEmail={customerEmail}
      orderNumber={orderNumber}
    />
  );

};

export default ConfirmationPageApplePay;
